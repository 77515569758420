import request from '@/utils/request';

export function uploadPhotosAPI(data) {
  return request({
    url: `upload/images`,
    method: 'post',
    data
  });
}

//  添加通知接口
export function addInformAPI(data) {
  return request({
    url: `/msg`,
    method: 'post',
    data
  });
}

// 用户消息列表接口
// h5/msg/user/pagination?current=1&size=10
export function getInformListAPI() {
  return request({
    url: `/h5/msg/user/pagination?current=1&size=10`,
    method: 'get'
  });
}

// 创建者消息列表
// h5/msg/manage/pagination
export function getCreateInformListAPI() {
  return request({
    url: `h5/msg/manage/pagination?current=1&size=10`,
    method: 'get'
  });
}

// 发布通知
// msg/publish/{msgId}
export function publishInformAPI(msgId, data) {
  return request({
    url: `msg/publish/${msgId}`,
    method: 'post',
    data
  });
}

// 班级已读未读情况
// /h5/msg/read-detail/dpt/{msgId}
export function getMsgReadAPI(msgId) {
  return request({
    url: `/h5/msg/read-detail/dpt/${msgId}`,
    method: 'get'
  });
}

//  班级具体已读未读人员情况
// /h5/msg/read-detail/user/{msgId}?dptId=107

export function getMsgReadDetailAPI(msgId, dptId) {
  return request({
    url: `/h5/msg/read-detail/user/${msgId}?dptId=${dptId}`,
    method: 'get'
  });
}

// 消息删除接口
// /msg/{msgId}
export function deletePublishAPI(msgId) {
  return request({
    url: `/msg/${msgId}`,
    method: 'post'
  });
}

// 消息编辑接口
// /msg
export function editPublishAPI(data) {
  return request({
    url: `/msg`,
    method: 'post',
    data
  });
}

// 用户已读未读   "h5/msg/read/msgId}"
export function userReadAPI(msgId) {
  return request({
    url: `h5/msg/read/${msgId}`,
    method: 'post'
  });
}

// 获取管理人员下面所有部门 "/dpts"
export function getAllChildrenAPI() {
  return request({
    url: `/dpts`,
    method: 'get'
  });
}
