<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">新增通知</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <span class="back">返回</span>
      </div>
    </div>
    <div class="title">
      <span>通知标题:</span>
      <input type="text" placeholder="请输入标题" v-model="inform.title" />
    </div>
    <div class="content">
      <h3>通知内容:</h3>
      <el-form>
        <el-input
          type="textarea"
          rows="8"
          v-model="inform.content"
          resize="none"
          placeholder="请输入通知内容"
        ></el-input>
      </el-form>
    </div>
    <!-- <div class="scope">
      <p class="left">通知范围:</p>
      <p class="right">&gt;</p>
    </div>-->
    <div class="upload">
      <ul class="image">
        <li v-for="(item,index) in inform.imgs" :key="index">
          <el-image
            class="up-load"
            :src="`${apiUrl}/image?name=${item}`"
            :preview-src-list="[`${apiUrl}/image?name=${item}`]"
          />
          <span @click="deletePhoto(index)">x</span>
        </li>
      </ul>
      <div style="display:flex;margin-top:1rem">
        <el-upload
          ref="upload"
          class="avatar-uploader"
          name="photos"
          :limit="num"
          :headers="headers"
          :action="apiUrl + '/upload/images'"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <el-button style="margin-bottom:0.5rem">上传图片</el-button>
        </el-upload>
        <!-- <p>
          <el-button>上传附件</el-button>
        </p>-->
      </div>
    </div>
    <div class="releasetime" @click="selectFn">
      <p class="left">通知范围:</p>
      <p class="right">&gt;</p>
    </div>
    <div class="timer">
      <p class="left">定时发布:</p>
      <p class="right">
        <el-switch
          v-model="inform.schedule"
          :active-value="1"
          :inactive-value="0"
          active-color="#13ce66"
          inactive-color="#ff4949"
        ></el-switch>
      </p>
    </div>
    <div class="releasetime" @click="publishTimeFn">
      <p class="left">发布时间: {{currentDate}}</p>
      <p class="right">&gt;</p>
    </div>
    <div class="submit">
      <p class="right">
        <el-button @click="publish">保 存</el-button>
      </p>
    </div>
    <van-popup
      v-model="show"
      position="bottom"
      :close-on-click-overlay="false"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择完整时间"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="cancelFn"
        @confirm="confirmFn"
      />
    </van-popup>
    <van-popup
      v-model="show2"
      position="bottom"
      :close-on-click-overlay="false"
      :style="{ height: '50%' }"
    >
      <div
        style="width:100%;height:2rem;line-height:2rem;text-align:right;padding-right:1rem"
        @click="show2=false"
      >确定</div>
      <van-checkbox-group v-model="result" @change="changeFn">
        <van-cell-group>
          <van-cell
            v-for="(item, index) in list"
            clickable
            :key="item.dptId"
            :title="`${item.dtpName}`"
            @click="toggle(index)"
          >
            <template #right-icon>
              <van-checkbox :name="item.dptId" ref="checkboxes" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </van-popup>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { Toast } from "vant";
import { addInformAPI, editPublishAPI, getAllChildrenAPI } from "@/api/inform";
import store from "@/store";
import moment from "moment";
export default {
  data() {
    return {
      content: "",
      value: false,
      fileList: [],
      apiUrl: process.env.VUE_APP_BASE_API,
      headers: {
        Authorization: "Bearer " + store.getters.token,
      },
      show: false,
      show2: false,
      minDate: new Date(),
      maxDate: new Date(),
      currentDate: "",
      inform: {
        organizationId: null,
        msgType: null,
        manageId: null,
        manageName: "",
        label: "通知",
        title: "",
        content: "",
        imgs: [],
        schedule: 0,
        publishTime: "",
        dptList: [],
      },
      result: [],
      action: "add",
      num: 5,
      cancel,
      list: [],
    };
  },
  created() {
    this.getTime();
    const user = JSON.parse(sessionStorage.getItem("personInfo"));
    this.inform.organizationId = user.organizationId;
    this.inform.manageId = user.id;
    this.inform.manageName = user.name;
    if (user.isManager || user.isClassManager) {
      this.inform.msgType = 1;
    }
    if (this.$route.query.item) {
      this.inform = JSON.parse(this.$route.query.item);
      this.action = "edit";
      this.currentDate = this.inform.publishTime;
      this.inform.dptList.forEach((item) => {
        this.result.push(item.dptId);
      });
    }
    this.getAllChildrenFn();
  },
  methods: {
    toBack() {
      this.$router.go(-1);
    },
    onRemove() {
      this.fileImg = "";
    },
    handleAvatarSuccess(res, file) {
      if (this.inform.imgs.length >= 5) {
        this.$message({
          message: "图片最多只能上传5张",
          type: "warning",
        });
        return;
      }
      this.inform.imgs.push(res.data[0].name);
    },
    beforeAvatarUpload(file, type) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("单张图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },
    getTime() {
      const year = moment().get("year");
      const month = moment().get("month") + 1;
      const day = moment().get("date");
      this.minDate = new Date();
      this.maxDate = new Date(year + 1, month, day);
    },
    confirmFn() {
      var date = new Date(this.currentDate);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      if (h < 10) {
        h = "0" + h;
      }
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      let time = y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + "00"; //这里如果不需要小时 分  后边的可以不需要拼接
      this.currentDate = time;
      this.show = false;
    },
    cancelFn() {
      this.currentDate = "";
      this.show = false;
    },
    publishTimeFn() {
      this.show = true;
    },
    async publish() {
      if (this.inform.dptList.length === 0) {
        Toast({
          message: "请选择发布范围!",
        });
        return;
      }
      this.inform.publishTime = this.currentDate;
      if (this.inform.content.trim() === "") {
        Toast({
          message: "通知内容不能为空!",
        });
        return;
      }
      if (this.inform.title.trim() === "") {
        Toast({
          message: "通知标题不能为空!",
        });
        return;
      }
      if (
        (this.inform.publishTime === "" && this.inform.schedule === 1) ||
        (this.inform.publishTime === null && this.inform.schedule === 1)
      ) {
        Toast({
          message: "请选择定时发布时间!",
        });
        return;
      }
      if (this.inform.schedule === 1 && this.getNowTime() > this.currentDate) {
        Toast({
          message: "定时发布时间必须大于当前时间",
        });
        return;
      }

      if (this.action === "add") {
        const res = await addInformAPI({
          ...this.inform,
          imgs: JSON.stringify(this.inform.imgs),
        });
        if (res.code === 0) {
          this.$router.push("/inform-list");
          Toast({
            message: "保存成功,请前往我的发布进行发布消息!",
          });
        }
      } else {
        const res = await editPublishAPI({
          ...this.inform,
          imgs: JSON.stringify(this.inform.imgs),
        });
        if (res.code === 0) {
          this.$router.push("/inform-list");
          Toast({
            message: "编辑成功,请前往我的发布进行发布消息!",
          });
        }
      }
    },
    deletePhoto(index) {
      this.inform.imgs.splice(index, 1);
      this.num++;
    },
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //获取完整的年份(4位,1970-????)
      let month = now.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let today = now.getDate(); //获取当前日(1-31)
      let hour = now.getHours(); //获取当前小时数(0-23)
      let minute = now.getMinutes(); //获取当前分钟数(0-59)
      let second = now.getSeconds(); //获取当前秒数(0-59)
      let nowTime = "";
      if (Number(hour) < 10) {
        hour = "0" + hour;
      }
      nowTime =
        year +
        "-" +
        this.fillZero(month) +
        "-" +
        this.fillZero(today) +
        " " +
        this.fillZero(hour) +
        ":" +
        this.fillZero(minute) +
        ":" +
        this.fillZero(second);
      return nowTime;
    },
    fillZero(str) {
      var realNum;
      if (str < 10) {
        realNum = "0" + str;
      } else {
        realNum = str;
      }
      return realNum;
    },
    getAllChildrenFn() {
      getAllChildrenAPI().then((res) => {
        if (res.code === 0) {
          this.list = res.data;
        }
      });
    },
    selectFn() {
      this.show2 = true;
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    changeFn(val) {
      this.inform.dptList = [];
      this.list.forEach((item) => {
        val.forEach((citem) => {
          if (item.dptId === citem) {
            this.inform.dptList.push({
              dptId: item.dptId,
              dptType: item.dptType,
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #ffff;
  & > .nav {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;
    z-index: 99;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
  }
  .title {
    width: 95%;
    margin: 0 auto;
    padding-top: 5rem;
    height: 2rem;
    line-height: 2rem;
    display: flex;
    border-bottom: 2px solid #eeeeee;
    span {
      width: 20%;
      font-size: 14px;
      font-weight: 700;
    }
    input {
      width: 80%;
      margin-top: 0.3rem;
      height: 20px;
      text-indent: 0.5rem;
      border: none;
      font-size: 12px;
    }
  }
  .content {
    width: 95%;
    margin: 0 auto;
    padding-bottom: 1rem;
    border-bottom: 2px solid #eeeeee;
    h3 {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .scope {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 4rem;
    line-height: 4rem;
    border-bottom: 2px solid #eeeeee;
    .left {
      font-size: 14px;
      font-weight: 700;
    }
    .right {
      width: 1rem;
      font-weight: 700;
      font-family: "宋体";
    }
  }
  .upload {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eeeeee;
    .image {
      width: 70%;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      li {
        width: 25%;
        margin: 1rem 0;
        position: relative;
        margin-right: 1rem;
        img {
          width: 100%;
          height: 100%;
          border: 1px dashed #cccccc;
        }
        span {
          position: absolute;
          display: inline-block;
          height: 1rem;
          width: 1rem;
          line-height: 1rem;
          text-align: center;
          color: #ffffff;
          border-radius: 0.5rem;
          background: red;
          font-weight: 700;
          top: -0.25rem;
          right: -0.25rem;
          font-family: "微软雅黑";
        }
      }
    }
  }
  .timer {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eeeeee;
    align-items: center;
    .left {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .releasetime {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eeeeee;
    .left {
      font-size: 14px;
      font-weight: 700;
    }
    .right {
      width: 1rem;
      font-weight: 700;
      font-family: "宋体";
    }
  }
  .submit {
    width: 95%;
    margin: 0 auto;
    // display: flex;
    // justify-content: space-between;
    margin-top: 4rem;
    padding-bottom: 2rem;
    text-align: right;
  }
}
</style>